<template>
  <svg version="1.1" viewBox="0 0 4500 4500" style="shape-rendering:geometricPrecision; text-rendering:geometricPrecision; image-rendering:optimizeQuality; fill-rule:evenodd; clip-rule:evenodd" xmlns="http://www.w3.org/2000/svg"><g>
        <path style="opacity:0.998" fill="#fefffe" d="M 1896.5,204.5 C 1900.5,204.5 1904.5,204.5 1908.5,204.5C 1908.5,205.5 1908.5,206.5 1908.5,207.5C 1924.84,207.64 1941.18,207.307 1957.5,206.5C 2061.63,213.433 2164.97,226.6 2267.5,246C 2349.49,262.996 2429.49,286.663 2507.5,317C 2752.66,408.441 2964.83,548.941 3144,738.5C 3335.9,942.5 3470.9,1180.17 3549,1451.5C 3637.76,1778.27 3631.76,2103.27 3531,2426.5C 3484.95,2569.24 3421.29,2703.24 3340,2828.5C 3335.45,2835.34 3334.12,2842.67 3336,2850.5C 3338.17,2854.84 3340.84,2858.84 3344,2862.5C 3627.64,3145.14 3910.97,3428.14 4194,3711.5C 4277.48,3802.69 4307.48,3909.36 4284,4031.5C 4270.32,4086.2 4244.66,4134.2 4207,4175.5C 4117.63,4274.95 4007.13,4311.45 3875.5,4285C 3816.47,4270.99 3764.81,4243.66 3720.5,4203C 3431.83,3914.33 3143.17,3625.67 2854.5,3337C 2847.51,3332.34 2840.51,3332.34 2833.5,3337C 2593.69,3492.72 2330.69,3582.39 2044.5,3606C 1867.94,3620.54 1693.61,3606.87 1521.5,3565C 1377.66,3533.4 1240.99,3482.74 1111.5,3413C 840.902,3266.41 623.735,3062.58 460,2801.5C 380.451,2671.71 318.784,2533.71 275,2387.5C 233.723,2234.22 211.389,2078.22 208,1919.5C 205.811,1851.52 209.478,1783.85 219,1716.5C 230.658,1635.54 245.658,1555.21 264,1475.5C 286.666,1384.17 317.666,1295.83 357,1210.5C 497.296,906.531 707.462,662.698 987.5,479C 1211.97,334.722 1457.3,247.722 1723.5,218C 1781.31,213.395 1838.98,208.895 1896.5,204.5 Z M 1871.5,718.5 C 2181.42,712.842 2453.76,809.675 2688.5,1009C 2884.65,1181 3012.48,1394.83 3072,1650.5C 3128.63,1916.17 3099.96,2172.17 2986,2418.5C 2870.73,2655.1 2697.57,2836.27 2466.5,2962C 2224.38,3087.78 1968.71,3127.78 1699.5,3082C 1420.22,3028.5 1188.06,2895 1003,2681.5C 825.33,2469.63 730.664,2224.63 719,1946.5C 713.19,1641.55 807.19,1372.55 1001,1139.5C 1171.73,941.314 1384.9,811.147 1640.5,749C 1716.71,731.683 1793.71,721.517 1871.5,718.5 Z" />
      </g>
      <g>
        <path style="opacity:0.994" fill="#fefffe" d="M 2122.5,960.5 C 2161.89,959.274 2191.39,975.608 2211,1009.5C 2220.69,1028.62 2223.03,1048.62 2218,1069.5C 2198.49,1143.88 2178.82,1218.21 2159,1292.5C 2082.49,1565.89 2008.15,1839.89 1936,2114.5C 1900.61,2244.34 1864.61,2374.01 1828,2503.5C 1800.02,2615.07 1770.02,2726.07 1738,2836.5C 1733.15,2852.21 1728.15,2867.88 1723,2883.5C 1710.22,2919.65 1684.72,2935.65 1646.5,2931.5C 1646.62,2933.48 1645.95,2935.15 1644.5,2936.5C 1630.81,2932.1 1617.14,2927.6 1603.5,2923C 1572.06,2909.87 1556.06,2886.21 1555.5,2852C 1555.4,2844.07 1556.23,2836.23 1558,2828.5C 1599.42,2670.82 1642.09,2513.48 1686,2356.5C 1713.31,2247.6 1742.64,2139.26 1774,2031.5C 1814.78,1875.06 1857.11,1719.06 1901,1563.5C 1944.29,1396.02 1989.29,1229.02 2036,1062.5C 2041.9,1039.45 2050.9,1017.78 2063,997.5C 2069.46,989.371 2076.29,981.537 2083.5,974C 2095.31,965.786 2108.31,961.286 2122.5,960.5 Z" />
      </g>
      <g>
        <path style="opacity:0.994" fill="#fefffe" d="M 1762.5,985.5 C 1801.2,982.773 1833.03,996.106 1858,1025.5C 1870.05,1042.02 1874.38,1060.36 1871,1080.5C 1819.82,1273.54 1768.49,1466.54 1717,1659.5C 1667.32,1836.21 1618.65,2013.21 1571,2190.5C 1560.47,2228.3 1549.47,2265.97 1538,2303.5C 1522.53,2333.62 1498.03,2351.45 1464.5,2357C 1433.78,2358.05 1408.94,2346.55 1390,2322.5C 1377.75,2303.25 1373.42,2282.25 1377,2259.5C 1378.94,2236.55 1382.6,2213.89 1388,2191.5C 1416.45,2081.49 1446.11,1971.82 1477,1862.5C 1508.8,1738.64 1542.46,1615.31 1578,1492.5C 1615.29,1349.68 1654.29,1207.35 1695,1065.5C 1698.8,1053.76 1702.8,1042.09 1707,1030.5C 1717.68,1005.63 1736.18,990.632 1762.5,985.5 Z" />
      </g>
      <g>
        <path style="opacity:0.994" fill="#fefffe" d="M 2409.5,1142.5 C 2432.53,1140.09 2453.87,1144.93 2473.5,1157C 2487.3,1165.66 2499.13,1176.49 2509,1189.5C 2517.33,1202.79 2519.99,1217.13 2517,1232.5C 2496.45,1315.71 2475.11,1398.71 2453,1481.5C 2400.13,1675.31 2346.8,1868.98 2293,2062.5C 2282.51,2085.66 2266.34,2103.83 2244.5,2117C 2220.58,2129.35 2196.58,2129.35 2172.5,2117C 2150.52,2107.69 2136.36,2091.52 2130,2068.5C 2124.19,2049.41 2123.19,2030.07 2127,2010.5C 2137.3,1972 2146.97,1933.33 2156,1894.5C 2186.18,1791.78 2214.18,1688.45 2240,1584.5C 2256,1527.83 2272,1471.17 2288,1414.5C 2301.38,1360.99 2315.38,1307.66 2330,1254.5C 2337.02,1231.79 2345.02,1209.45 2354,1187.5C 2364.68,1162.63 2383.18,1147.63 2409.5,1142.5 Z" />
      </g>
      <g>
        <path style="opacity:0.993" fill="#fefffe" d="M 2127.5,2226.5 C 2162.04,2227.36 2190.54,2240.69 2213,2266.5C 2225.23,2282.19 2229.9,2299.85 2227,2319.5C 2213.25,2377.16 2198.25,2434.49 2182,2491.5C 2167.87,2538.89 2153.54,2586.23 2139,2633.5C 2132.92,2652.73 2126.26,2671.73 2119,2690.5C 2109.63,2708.86 2094.8,2720.03 2074.5,2724C 2039.93,2728.69 2009.59,2719.69 1983.5,2697C 1965.02,2678.56 1957.52,2656.39 1961,2630.5C 1991.5,2511.01 2024.83,2392.35 2061,2274.5C 2073.61,2245.03 2095.78,2229.03 2127.5,2226.5 Z" />
      </g>
    </svg>
</template>

<script>
export default {
  name: 'search',
}
</script>
